import { Redirect, Route } from 'react-router-dom';

import AboutPage from './AboutPage';
import ContactPage from './ContactPage';
import ContentPage from './ContentPage';
import ContentProvider from '../../components/ContentProvider';
import LandingPage from './LandingPage';
import PrivacyPage from './PrivacyPage';
import PromoPage from './PromoPage';
import PropTypes from 'prop-types';
import React from 'react';
import ReviewPage from './ReviewPage';
import ServiceCategoriesPage from './ServiceCategoriesPage';
import ServiceRedirect from './ServiceRedirect';
import TermsPage from './TermsPage';
import config from '../../config';

// default CMS content page

// returns a render function for a component with a ContentProvider wrapper
const wrapped = (Component, pageType='page') => {
  const hoc = (props) => <ContentProvider component={Component} pageType={pageType} {...props} />;
  hoc.displayName = 'wrapped';
  return hoc;
};
const ExternalRedirect = ({to}) => {window.location.href = to; return null;};

ExternalRedirect.propTypes = {
  to: PropTypes.string,
};

const routes = () => {
  return [
    <Route key="/about" path="/about" render={wrapped(AboutPage)}/>,
    <Route key="/faq" path="/faq" render={() => <ExternalRedirect to={config.link.faq}/>}/>,
    <Route key="/privacy" path="/privacy" render={wrapped(PrivacyPage)}/>,
    <Route key="/how-to-write-a-review" path="/how-to-write-a-review" render={() => <Redirect to="/review/"/>}/>,
    <Route key="/why-subscribe" path="/why-subscribe" render={() => <Redirect to="/about-accreditation/"/>}/>,
    <Route key="/about-accreditation" path="/about-accreditation" render={wrapped(ContentPage)}/>,
    <Route key="/terms" path="/terms" render={wrapped(TermsPage)}/>,
    <Route key="/providers/faq" exact={true} path="/providers/faq" render={() => <ExternalRedirect to={config.link.providerFaq}/>}/>,
    <Route key="/provider-faq" path="/provider-faq" render={() => <ExternalRedirect to={config.link.providerFaq}/>}/>,
    <Route key="/contact" path="/contact" render={wrapped(ContactPage)}/>,
    <Route key="/team" path="/team" render={() => <Redirect to="/about/"/>}/>,
    <Route key="add-a-listing" path="/add-a-listing" render={() => <Redirect to="/providers/signup"/>}/>,
    <Route key="/review" path="/review" render={wrapped(ReviewPage)}/>,
    <Route key="/categories-explained" path="/categories-explained" render={() => <ExternalRedirect to={config.link.faq}/>}/>,
    <Route key="/blog-guidelines" path="/blog-guidelines" render={wrapped(ContentPage)}/>,
    <Route key="/content-guidelines" path="/content-guidelines" render={wrapped(ContentPage)}/>,
    <Route key="/expo" path="/expo" render={wrapped(ContentPage)}/>,
    <Route key="/service" path="/service/:slug" exact={false} render={ServiceRedirect}/>,
    <Route key="/servicecategories" path="/servicecategories" component={ServiceCategoriesPage}/>,
    config.features.iphoneGiveaway.enabled && <Route key="/ndis-services-giveaway" path="/ndis-services-giveaway" render={PromoPage}/>,
    // landing pages
    <Route
      key="/ndis-support-work"
      path="/ndis-support-work"
      component={wrapped(LandingPage, 'landingpage')}
    />,
    <Route
      key="/ndis-support-coordination"
      path="/ndis-support-coordination"
      component={wrapped(LandingPage, 'landingpage')}
    />,
    <Route
      key="/ndis-plan-management"
      path="/ndis-plan-management"
      component={wrapped(LandingPage, 'landingpage')}
    />,
    <Route
      key="/ndis-occupational-therapy"
      path="/ndis-occupational-therapy"
      component={wrapped(LandingPage, 'landingpage')}
    />,
    <Route
      key="/ndis-physiotherapy"
      path="/ndis-physiotherapy"
      component={wrapped(LandingPage, 'landingpage')}
    />,
    <Route
      key="/ndis-speech-pathology"
      path="/ndis-speech-pathology"
      component={wrapped(LandingPage, 'landingpage')}
    />,
    <Route
      key="/ndis-garden-maintenance"
      path="/ndis-garden-maintenance"
      component={wrapped(LandingPage, 'landingpage')}
    />,
    <Route
      key="/ndis-cleaning"
      path="/ndis-cleaning"
      component={wrapped(LandingPage, 'landingpage')}
    />,
    <Route
      key="/ndis-psychology"
      path="/ndis-psychology"
      component={wrapped(LandingPage, 'landingpage')}
    />,
    <Route
      key="/ndis-navigator"
      path="/ndis-navigator"
      component={wrapped(LandingPage, 'landingpage')}
    />,
  ];
};

export default routes;
