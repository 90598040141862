import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import Confetti from 'react-confetti';
import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshake,
  faPhone,
  faEnvelope,
  faAddressCard,
  faUserTie,
  faComment,
  faMessageSms,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleCheck,
  faCircleExclamation,
  faArrowDown,
} from '@fortawesome/pro-duotone-svg-icons';

import { ServiceRequestAPI, useMutateApi } from 'providerSite/api';
import Dropdown from '../questionComponents/Dropdown';
import {
  CONTACT_METHOD_FIELD,
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PARTICIPANT_FIRST_NAME_FIELD,
  PARTICIPANT_LAST_NAME_FIELD, PREFERRED_CONTACT_METHOD_EMAIL,
  PREFERRED_CONTACT_METHOD_PHONE_CALL, PREFERRED_CONTACT_METHOD_SMS,
  RELATION_CHILD,
  RELATION_OTHER,
  RELATION_PARENT,
  RELATION_PARTNER,
  RELATION_RELATIVE,
  RELATION_SUPPORT_COORDINATOR,
  RELATIONSHIP_FIELD,
  SERVICE_FOR_TYPE_MYSELF, SERVICE_REQUEST_ANSWERS_FIELD,
  SERVICES_FOR_FIELD
} from '../../constants';
import Textbox from '../questionComponents/Textbox';
import Divider from 'components/Divider';
import animationAsset from 'assets/man-relaxing.json';
import SwitchCard from '../components/SwitchCard';
import ServiceSpecificQuestions from '../questions/ServiceSpecificQuestions';
import HowItWorksCard from './HowItWorksCard';
import style from 'style';


const Container = styled.div`
  max-width: ${style.sizes.medium};
  padding: 20px;
  
  .flex-header {
    display: flex;
    align-items: center;
    column-gap: 30px;
    justify-content: space-between;
    
    @media (max-width: ${style.sizes.small}) {
      flex-direction: column;
      row-gap: 20px;
    }
  }
  
  .lottie-container {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
    flex-shrink: 1;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 30px;
  
  .comm-container {
    display: flex;
    column-gap: 80px;
    
    @media (max-width: ${style.sizes.small}) {
      flex-direction: column;
      row-gap: 20px;
    }
  }
  
  .scroll-down-text {
    font-weight: bold;
    color: ${style.colors.secondary};
    cursor: pointer;
    user-select: none;
    
    :hover {
      text-decoration: underline;
    }
  }
  
  .comm-pref-title {
    margin-bottom: 13px;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
    font-weight: 500;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  gap: 25px 80px;
  margin-top: 20px;
  flex-wrap: wrap;
  
  @media (max-width: ${style.sizes.small}) {
    flex-direction: column;
  }
`;

const CommQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 15px;
  
  .flex {
    display: flex;
    column-gap: 80px;
    
    @media (max-width: ${style.sizes.small}) {
      flex-direction: column;
      row-gap: 20px;
    }
  }
`;

const Button = styled(AntdButton)`
  width: 100%;
  max-width: 300px;
  height: 40px;
  border-radius: 12px;
  scroll-margin-top: 20px;
  
  @media (max-width: ${style.sizes.small}) {
    max-width: unset;
  }
`;


const AdditionalQuestions = ({ data, serviceInquiryUuid }) => {

  const [inquiryAnswers, setInquiryAnswers] = useState({});
  const [serviceAnswers, setServiceAnswers] = useState({});
  const [isPhoneChecked, setIsPhoneChecked] = useState(true);
  const [isEmailChecked, setIsEmailChecked] = useState(true);
  const [isSmsChecked, setIsSmsChecked] = useState(true);

  const history = useHistory();
  const saveButtonRef = useRef(null);

  const serviceRequest = data.serviceRequests ? data.serviceRequests[0] : {};

  const updateMutation = useMutateApi(ServiceRequestAPI.PATCH_SERVICE_INQUIRY, 'PATCH', {
    params: {
      serviceInquiryUuid,
    },
    onSuccess: () => {
      history.replace({state: {}});
    },
  });

  const isForMyself = data.data?.[SERVICES_FOR_FIELD] === SERVICE_FOR_TYPE_MYSELF;

  const setInquiryAnswer = key => value => {
    setInquiryAnswers(prevState => ({...prevState, [key]: value}));
  };

  const setServiceAnswer = key => value => {
    setServiceAnswers(prevState => ({...prevState, [key]: value}));
  };

  const getContactMethod = () => {
    if (isPhoneChecked) return PREFERRED_CONTACT_METHOD_PHONE_CALL;
    if (isSmsChecked) return PREFERRED_CONTACT_METHOD_SMS;
    return PREFERRED_CONTACT_METHOD_EMAIL;
  };

  const handleUpdate = () => {
    updateMutation.mutate({
      ...inquiryAnswers,
      [CONTACT_METHOD_FIELD]: getContactMethod(),
      [PARTICIPANT_LAST_NAME_FIELD]: inquiryAnswers[isForMyself ? LAST_NAME_FIELD : PARTICIPANT_LAST_NAME_FIELD],
      [SERVICE_REQUEST_ANSWERS_FIELD]: serviceAnswers,
    });
  };

  return (
    <Container>
      <div className="flex-header">
        <div className="lottie-container">
          <Lottie
            animationData={animationAsset}
            style={{maxWidth: '450px', margin: '-60px -80px'}}
            loop
          />
        </div>
        <HowItWorksCard
          name={data.firstName}
          providerCount={serviceRequest.data.providerCount}
          style={{maxWidth: '600px'}}
          trackingPage={false}
        />
      </div>
      <Content>
        <Divider name="Communication Preference" style={{marginBottom: '20px'}}/>
        <div>
          <div className="comm-pref-title">
            <FontAwesomeIcon icon={faComment} style={{marginRight: '7px', fontSize: '16px'}}/>
            How do our providers reach out to discuss your service?
          </div>
          <div className="comm-container">
            <SwitchCard
              recommended
              title="Phone"
              value={isPhoneChecked}
              onChange={setIsPhoneChecked}
              disabled={!isEmailChecked && !isSmsChecked}
              icon={faPhone}
              first_node={data.phone}
              last_node={(
                <>
                  <span>Verified via SMS</span>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{marginLeft: '5px', verticalAlign: '-1px', color: style.colors.success}}
                  />
                </>
              )}
            />
            <SwitchCard
              title="Email"
              value={isEmailChecked}
              onChange={setIsEmailChecked}
              disabled={!isPhoneChecked && !isSmsChecked}
              icon={faEnvelope}
              first_node={data.email}
              activeColor={style.colors.warning}
              inactiveColor="#aeaeae"
              last_node={(
                <>
                  <span>Not Verified</span>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{marginLeft: '5px', verticalAlign: '-2px', color: style.colors.warning}}
                  />
                </>
              )}
            />
          </div>
          <div className="comm-container" style={{marginTop: '20px'}}>
            <SwitchCard
              title="Text"
              value={isSmsChecked}
              onChange={setIsSmsChecked}
              disabled={!isPhoneChecked && !isEmailChecked}
              icon={faMessageSms}
              first_node={data.phone}
              activeColor={style.colors.warning}
              inactiveColor="#aeaeae"
              last_node={(
                <>
                  <span>Verified via SMS</span>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{marginLeft: '5px', verticalAlign: '-1px', color: style.colors.success}}
                  />
                </>
              )}
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'end', marginTop: '20px', alignItems: 'center'}}>
            <FontAwesomeIcon
              style={{marginRight: '7px', color: style.colors.secondary, fontSize: '16px'}}
              icon={faArrowDown}
              beat
            />
            <div
              className="scroll-down-text"
              onClick={() => saveButtonRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start'})}
            >
              Scroll down to bottoms to save your preferences
            </div>
          </div>
        </div>
        <CommQuestionContainer>
          <Divider name="Contact Details (Optional)" style={{marginBottom: '5px'}}/>
          {!isForMyself && (
            <div style={{maxWidth: '440px'}}>
              <Dropdown
                title="Relationship to participant"
                icon={faHandshake}
                options={[
                  RELATION_PARENT,
                  RELATION_CHILD,
                  RELATION_PARTNER,
                  RELATION_RELATIVE,
                  RELATION_SUPPORT_COORDINATOR,
                  RELATION_OTHER,
                ]}
                onChange={setInquiryAnswer(RELATIONSHIP_FIELD)}
              />
            </div>
          )}
          <div className="flex">
            <Textbox
              title="Your First Name"
              icon={faUserTie}
              defaultData={data[FIRST_NAME_FIELD]}
              disabled
              onChange={setInquiryAnswer(FIRST_NAME_FIELD)}
            />
            <Textbox
              title="Your Last Name"
              icon={faUserTie}
              onChange={setInquiryAnswer(LAST_NAME_FIELD)}
            />
          </div>
          {!isForMyself && (
            <div className="flex">
              <Textbox
                title="Participant's First Name"
                defaultData={data[PARTICIPANT_FIRST_NAME_FIELD]}
                icon={faAddressCard}
                disabled
                onChange={setInquiryAnswer(PARTICIPANT_FIRST_NAME_FIELD)}
              />
              <Textbox
                title="Participant's Last Name"
                icon={faAddressCard}
                onChange={setInquiryAnswer(PARTICIPANT_LAST_NAME_FIELD)}
              />
            </div>
          )}
        </CommQuestionContainer>
        <Divider
          name={`Additional Details - ${serviceRequest.serviceName} (Optional)`}
          style={{marginTop: '30px'}}
        />
        <QuestionContainer>
          <ServiceSpecificQuestions
            categoryName={serviceRequest.categoryName}
            setAnswer={setServiceAnswer}
          />
        </QuestionContainer>
      </Content>
      <div style={{display: 'flex', width: '100%', marginTop: '60px', marginBottom: '10px'}}>
        <Button
          ref={saveButtonRef}
          onClick={handleUpdate}
          loading={updateMutation.isLoading}
          type="primary"
        >
          Save
        </Button>
      </div>
      <Confetti style={{position: 'fixed'}} recycle={false} numberOfPieces={700} gravity={0.15} />
    </Container>
  );
};

AdditionalQuestions.propTypes = {
  data: PropTypes.object,
  serviceInquiryUuid: PropTypes.string,
};


export default AdditionalQuestions;

